import TYPES from '@/types';
import { phoneFormat, requiredRule, requiredRuleByKey } from '@/vue-app/utils/form-rules';

// Application
import {
  SearchMaritalStatusesQuery,
} from '@/modules/my-investment/catalogs/kuspit/marital-status/application/queries';
import {
  SearchStatesQuery,
} from '@/modules/my-investment/catalogs/kuspit/state/application/queries';
import {
  SearchCountriesQuery,
} from '@/modules/my-investment/catalogs/kuspit/country/application/queries';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import { GetCustomerPersonalInformationQuery }
  from '@/modules/customer/personal-information/application/queries';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import { v4 as uuid } from 'uuid';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import {
  CountryEntity,
} from '@/modules/my-investment/catalogs/kuspit/country/domain/entities/country-entity';
import {
  StateEntity,
} from '@/modules/my-investment/catalogs/kuspit/state/domain/entities/state-entity';
import {
  MaritalStatusEntity,
} from '@/modules/my-investment/catalogs/kuspit/marital-status/domain/entities/marital-status-entity';

import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsPersonalInformationContactKuspitViewModel {
  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_MARITAL_STATUS_QUERY)
  readonly searchMaritalStatusesQuery!: SearchMaritalStatusesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_STATE_QUERY)
  readonly searchStatesQuery!: SearchStatesQuery;

  @Inject(TYPES.SEARCH_CATALOG_KUSPIT_COUNTRY_QUERY)
  readonly searchCountriesQuery!: SearchCountriesQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly searchInvestmentProvidersQuery!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly updateOnBoardingStepCommand!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.GET_CUSTOMER_PERSONAL_INFORMATION_QUERY)
  private readonly get_customer_personal_information_query!: GetCustomerPersonalInformationQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  i18n_namespace = 'components.contract-savings.personal-information.contact';

  small_screen: null | boolean = null;

  country_codes = ['+52', '+1'];

  inputs = {
    phone: '',
    phone_country_code: this.country_codes[0],
    marital_status: {
      id: '',
      descripcion: '',
    },
    birth_country: {
      id: '',
      descripcion: '',
    },
    birth_state: {
      id: '',
      descripcion: '',
    },
  };

  rules = {
    select: [requiredRule],
    phone: [
      requiredRule,
      (value: string) => phoneFormat('+52', value),
    ],
    marital_status: [(value: string) => requiredRuleByKey(value, 'id')],
    birth_country: [(value: string) => requiredRuleByKey(value, 'id')],
    birth_state: [(value: string) => requiredRuleByKey(value, 'id')],
  };

  investment_provider_name = 'kuspit';

  exists_step = false;

  step_name = 'on_boarding_kuspit_contact';

  investment_provider_id = '';

  countries: Array<CountryEntity> = [];

  states: Array<StateEntity> = [];

  marital_statuses: Array<MaritalStatusEntity> = [];

  is_loading = true;

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      cellphone_number: '',
      marital_status: {
        id: '',
        description: '',
      },
      country_of_birth: {
        id: '',
        description: '',
      },
      state_of_birth: {
        id: '',
        description: '',
      },
    },
  };

  loadDefaultPhone = async () => {
    const {
      cellphone_country_code, cellphone,
    } = await this.get_customer_personal_information_query.execute();
    this.inputs.phone_country_code = cellphone_country_code || this.country_codes[0];
    this.inputs.phone = cellphone;
  }

  loadStates = async () => {
    if (this.inputs.birth_country.id) {
      this.states = await this.searchStatesQuery.execute(this.inputs.birth_country.id);
    } else {
      this.states = [];
    }
  }

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
    } else {
      await this.loadDefaultPhone();
    }
  };

  setInputsDataFromStep = () => {
    this.inputs.phone = this.on_boarding_step.payload.cellphone_number;
    this.inputs.phone_country_code = this.on_boarding_step.payload.phone_country_code;
    this.inputs.marital_status = {
      id: this.on_boarding_step.payload.marital_status.id,
      descripcion: this.on_boarding_step.payload.marital_status.description,
    };
    this.inputs.birth_country = {
      id: this.on_boarding_step.payload.country_of_birth.id,
      descripcion: this.on_boarding_step.payload.country_of_birth.description,
    };
    this.inputs.birth_state = {
      id: this.on_boarding_step.payload.state_of_birth.id,
      descripcion: this.on_boarding_step.payload.state_of_birth.description,
    };
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.inputs.phone = this.inputs.phone.replace(/\D/g, '');
      this.on_boarding_step.payload = {
        cellphone_number: this.inputs.phone,
        phone_country_code: this.inputs.phone_country_code,
        marital_status: {
          id: this.inputs.marital_status.id,
          description: this.inputs.marital_status.descripcion,
        },
        country_of_birth: {
          id: this.inputs.birth_country.id,
          description: this.inputs.birth_country.descripcion,
        },
        state_of_birth: {
          id: this.inputs.birth_state.id,
          description: this.inputs.birth_state.descripcion,
        },
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.updateOnBoardingStepCommand.execute(this.on_boarding_step);
      } else {
        await this.createOnBoardingStepCommand.execute(this.on_boarding_step);
      }
    } catch {
      this.messageNotifier.showErrorNotification(
        'Ha ocurrido un error al guardar tu información, inténtalo nuevamente',
      );
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    const investment_providers = await this.searchInvestmentProvidersQuery.execute();
    this.setInvestmentProviderId(investment_providers);

    this.countries = await this.searchCountriesQuery.execute();

    this.marital_statuses = await this.searchMaritalStatusesQuery.execute();

    const steps = await this.searchOnBoardingStepsQuery.execute(this.investment_provider_id);
    this.verifyStep(steps);

    this.is_loading = false;
  }
}
