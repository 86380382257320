






























































































import {
  Vue,
  Component,
  PropSync,
  Watch,
} from 'vue-property-decorator';
import { VForm } from '@/vue-app/types/vuetify';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsPersonalInformationContactKuspitViewModel
  from '@/vue-app/view-models/components/contract-savings/personal-information/contact/contract-savings-personal-information-contact-kuspit-view-model';

@Component({
  name: 'ContractSavingsPersonalInformationContactKuspit',
  components: {
    CustomAutocomplete,
  },
})
export default class ContractSavingsPersonalInformationContactKuspit extends Vue {
  @PropSync('contactForm', { type: Boolean })
  synced_contact_form!: boolean;

  personal_information_kuspit_view_model = Vue.observable(
    new ContractSavingsPersonalInformationContactKuspitViewModel(),
  );

  $refs!: {
    contact_form: VForm;
  };

  @Watch('personal_information_kuspit_view_model.inputs.birth_country.id')
  onCountryChange() {
    this.personal_information_kuspit_view_model.loadStates();
  }

  @Watch('personal_information_kuspit_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loading', is_loading);
  }

  async created() {
    this.personal_information_kuspit_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.personal_information_kuspit_view_model.initialize();

    this.$emit('loadingInfo', this.personal_information_kuspit_view_model.is_loading);
  }

  async saveStep() {
    const saved = await this.personal_information_kuspit_view_model.saveStep();
    return saved;
  }
}

